import * as React from 'react';
import { Container, Stack, Box, Typography, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import Grid from '@mui/material/Grid2';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';

const Layout = (props) => {
  console.log("isLoadind: " + props.isLoading);

  const getStartTimeFromLocalStorage = () => {
    console.log("Refreshing start time");
    let startTime = localStorage.getItem("START_TIME");
    let epochTime = new Date(Number(startTime)).getTime();
    let currentEpoch = new Date().getTime();
    if (epochTime < (currentEpoch-12*60*60*1000)) {
        console.log("Removing old start time");
        localStorage.removeItem("START_TIME");
        return null
    } else {
      return dayjs(new Date(Number(startTime)));
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [numberOfPresent, setNumberOfPresent] = React.useState(0);
  const [starttime, setStarttime] = React.useState(getStartTimeFromLocalStorage());
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setInterval(() => {
      loadNumberOfPresent();
    }, 2000 * 60);

    loadNumberOfPresent();
    console.log("Debug localStartTime: " , starttime);

    return () => {
      clearInterval(timeoutId);
    }
  });


  const resetLogin = () => {
    localStorage.removeItem("schiessbuch_token");
    window.location.reload();
  }

  const refreshStarttime = () => {
    console.log("Refreshing start time");
    let startTime = localStorage.getItem("START_TIME");
    let epochTime = new Date(Number(startTime)).getTime();
    let currentEpoch = new Date().getTime();
    if (epochTime < (currentEpoch-12*60*60*1000)) {
        console.log("Removing old start time");
        localStorage.removeItem("START_TIME");
        setStarttime(null);
    } else {
      setStarttime(dayjs(new Date(Number(startTime))));
    }
  }

  const loadNumberOfPresent = () => {

    if (props.token) {
      console.log("Loading presence");
      fetch("api/count_present", {
        headers: {
          "token": props.token,
          "Content-Type": "application/json"
        },
        method: "GET",
      }).then(function (response) {
        response.json().then((result) => {
          console.log("Number of people present: " + result.counter);
          setNumberOfPresent(result.counter);
        });
      });
    }
  };

  const pushStartTime = (time) => {

    let current_millis = time.getTime();
    let message = {
      start: current_millis
    }
    console.log("Setting start time: " + time);
    fetch("api/set_presence", {
      headers: {
        "token": props.token,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(message)
    }).then(function (response) {
      console.log("setStartTime resonse: " + response);
      localStorage.setItem("START_TIME", current_millis);
      setStarttime(dayjs(new Date(Number(current_millis))));
      loadNumberOfPresent();
    });
  }


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let output;

  if (props.isLoading) {
    output = <Container maxWidth="sm">
      <Stack spacing={2}>
        <Box sx={{ display: 'flex' }} justifyContent="center">
          <Typography variant="h6" gutterBottom>
            Loading....
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }} justifyContent="center">
          <CircularProgress />
        </Box>
      </Stack>
    </Container>;
  } else {
    output = <Outlet context={[props.token, props.clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime]} />;
  }

  /*   const handleMenuClick = () => {
      console.log("Menu was clicked");
    }
   */
  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} style={{ "marginBottom": "10px" }}>
        <Grid size={8} >
          <img src="BSF_Logo.svg" alt="BSF" style={{ "maxHeight": "3rem" }} onClick={() => navigate("/")} />
        </Grid>

        <Grid container size={4} justifyContent="flex-end" alignItems="center" gap="20%">
          {numberOfPresent > 0 &&
          <Link to="current">
            <Badge badgeContent={numberOfPresent} color="success">
              <PeopleIcon color="action"/>
            </Badge>
          </Link>
          }

          <MenuOpenIcon onClick={handleMenuClick} />
        </Grid>
      </Grid>
      {output}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { handleMenuClose(); navigate("/"); }}>Zur Erfassung</MenuItem>
        <MenuItem onClick={() => { pushStartTime(new Date()); setAnchorEl(null); }}>Anwesenheit teilen</MenuItem>
        <MenuItem onClick={resetLogin}>Reset Login</MenuItem>
      </Menu>
    </Container >


  )
};

export default Layout;