import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';

const History = () => {

    const [token] = useOutletContext();
    const [entries, setEntries] = useState([]);
    const [entriesOfAllMembers, setEntriesOfAllMembers] = useState(false);
    const [username, setUsername] = useState();
    const navigate = useNavigate();

    const loadEntries = () => {
        console.log("Loading entries...");
        fetch("api/entries", {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                setEntries(result);
            });

        });
    }

    const loadAllEntries = () => {
        console.log("Loading entries...");
        fetch("api/all_entries", {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                console.log("Got the following result:" + JSON.stringify(result));
                setEntries(result);
            });

        });
    }

    useEffect(() => {
        console.log("Token in History: " + token);
        let tusername = JSON.parse(atob(JSON.parse(token).content)).username;
        console.log("Username in History: " + tusername);
        setUsername(tusername);

        loadEntries();
    }, []);

    const renderActions = (entry) => {

        let timelimit = new Date().valueOf() - 600000;
        if (entry.creation_timestamp > timelimit && entry.created_by === username) {
            return <CardActions>
                <IconButton color="primary"
                    onClick={() => {
                        fetch("api/delete_entry", {
                            headers: {
                                "token": token,
                                "Content-Type": "application/json"
                            },
                            method: "POST",
                            body: JSON.stringify({ "id": entry.id })
                        }).then(function (response) {
                            loadEntries();

                        });
                    }}>
                    <DeleteIcon />
                </IconButton>
            </CardActions>

        } else {
            return "";
        }


    }

    const listItems = entries.map((entry) =>
        <Card>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="h2">
                            {entry.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            Datum:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="h2">
                            {entry.datum}
                        </Typography>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="caption" component="h2">
                            Start:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            {entry.start}
                        </Typography>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="caption" component="h2">
                            Ende:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            {entry.ende}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            Scheiben:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="h2">
                            {entry.scheiben.join(", ")}
                        </Typography>
                    </Grid>
                    {entry.gaeste !== "" &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="caption" component="h2">
                                    Gäste:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="caption" component="h2">
                                    {entry.gaeste}
                                </Typography>
                            </Grid>
                        </>
                    }

                    {entry.weitere_mitglieder !== "" &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="caption" component="h2">
                                    Weiter Mitglieder:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="caption" component="h2">
                                    {entry.weitere_mitglieder}
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>

            </CardContent>
            {renderActions(entry)}
        </Card >
    );

    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    };

    return (
        <>
            <FormControl
                orientation="horizontal"
                sx={{ width: 300, justifyContent: 'space-between' }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={entriesOfAllMembers}
                            onChange={(event) => { 
                                let state = event.target.checked;
                                setEntriesOfAllMembers(event.target.checked);
                                if(state) {
                                    loadAllEntries();
                                } else {
                                    loadEntries();
                                }

                            }}
                        />
                    }
                    label="Einträge aller Mitglieder"
                />
            </FormControl>
            <Stack spacing={2}>
                {listItems}
            </Stack>
        </>
    );
}

export default History;