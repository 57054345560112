import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import History from "./pages/History";
import Current from "./pages/Current";

function App() {
  let API_URL = "/";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const requestCode = urlParams.get('code');

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(undefined);
  const [clientConfig, setClientConfig] = useState(undefined);

  useEffect(() => {
    console.log("Found following url code parameter:", requestCode);

    if (clientConfig === undefined) {
      fetch(API_URL + "api/clientconfig")
        .then(function (response) { return response.json(); })
        .then(function (json) {
          console.log("Received following clientConfig: " + JSON.stringify(json));
          setClientConfig(json);
        })
    } else {
      checkAuthState();
    }
    ;
  }, [clientConfig]);




  const checkAuthState = () => {
    let currentToken = localStorage.getItem("schiessbuch_token");

    //let currentToken = '{ "content": "eyJ1c2VybmFtZSI6ImFsZXhhbmRlci53dWxmZXJ0IiwiZGlzcGxheV9uYW1lIjoiQWxleGFuZGVyIFd1bGZlcnQiLCJjcmVhdGVkIjoxNzE1ODkwOTcwMjA4fQ==", "sig": "untrusted comment: signature from rsign secret key\\nRURK7y3x4FRjk/1I3gO0XADK3a71qXbWZwkxJUnvlK4dUhIYnmxRY5A6/CT40oZsZyIjK+/04ZYj4s+QJXz44Ev7PT93xclC1wc\\ntrusted comment: timestamp:1715890970\\nm6ADuYJb0+nBrYMMHAJdCKjELTZRuy0gnkLoSsmGDNrObB5XEy7NXItXTws4KZQCAsm6kTw1mdY1HgGM3Qs2Dg==\\n" }';

    //let currentToken = '{"content":"eyJ1c2VybmFtZSI6ImFsZXhhbmRlci53dWxmZXJ0IiwiZGlzcGxheV9uYW1lIjoiQWxleGFuZGVyIFd1bGZlcnQiLCJjcmVhdGVkIjoxNzE3ODU4Mjk3MDU2LCJncm91cHMiOlsiRXZlbnRLYXNzZSIsImV2ZW50cyJdfQ==","sig":"untrusted comment: signature from rsign secret key\\nRURK7y3x4FRjkzIDyWglSR7v3XaywfgRTARpMhGnoASsdou5AFAuP2DZIARBFvPw5a4zZrNl61dqj1uRVgW8MnyVzqI4N6+AkgA=\\ntrusted comment: timestamp:1717858297\\n4fhoUNjqPRz7qxOpXM8yAmicWXCT9jOoSMAoFUQ6T4w+TQYilE3CA5dSWREklO+Y9txBUjyx6p6iSaXDG9SrBA==\\n"}';

    if (!currentToken) {
      if (requestCode !== null) {
        console.log("Getting auth with request code " + requestCode);
        fetch(API_URL + "api/auth?code=" + requestCode, {
          method: 'POST'
        })
          .then(function (response) { return response.json(); })
          .then(function (json) {
            console.log("Received following token: " + json);
            localStorage.setItem("schiessbuch_token", JSON.stringify(json));
            setToken(JSON.stringify(json));
            window.history.replaceState(null, '', window.location.pathname);
            console.log("setting isLoading to false");
            setIsLoading(false);
          });

      } else {
        console.log("No current token found. Redirecting to login page");
        console.log(clientConfig);
        let redirectUrl = clientConfig.nextcloud_url + "apps/oauth2/authorize?response_type=code&client_id=" + clientConfig.client_id;
        console.log("Redirecting to " + redirectUrl);
        window.location.href = redirectUrl;
      }
    } else {
      setToken(currentToken);
      console.log("Got token: " + currentToken);
      console.log("setting isLoading to false");
      setIsLoading(false);
    }
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout isLoading={isLoading} token={token} clientConfig={clientConfig}/>}>
          <Route index element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/current" element={<Current />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
