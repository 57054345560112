import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import 'dayjs/locale/de';
import { FormControl, SliderValueLabel } from '@mui/material';
import dayjs from 'dayjs';
import { useOutletContext } from "react-router-dom";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

const Home = () => {

    const scheiben = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
    ];

    const parseToken = function (token) {
        let t = JSON.parse(token);
        return JSON.parse(decodeBase64(t.content));
    }

    const decodeBase64 = (input) => {
        let bytes =  Uint8Array.from(atob(input), c => c.charCodeAt(0)); 
        return new TextDecoder().decode(bytes);
    }

    const [startTimeError, setStartTimeError] = useState();
    const [scheibenError, setScheibenError] = useState();

    const [token, clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime] = useOutletContext();
    const [scheibennummer, setScheibennummer] = React.useState([]);
    const [endtime, setEndtime] = React.useState(dayjs());
    const [date, setDate] = React.useState(dayjs());
    const [displayName, setDisplayName] = React.useState(parseToken(token).display_name);
    const [additionalMembers, setAdditionalMembers] = React.useState("");
    const [guests, setGuests] = React.useState("");
    const [isSaveSuccessful, setIsSaveSuccessfull] = React.useState(false);
    const [isPublic, setPublic] = React.useState(false);
    const [openPublicInfo, setOpenPublicInfo] = React.useState(false);
    const [showPositionDialog, setShowPositionDialog] = React.useState(false);
    const [geoLocationId, setGeoLocationId] = React.useState(null);

    const handleRemoveScheibennummer = (e) => {
        const newScheiben = scheibennummer.filter(nummer => nummer!== e);
        setScheibennummer(newScheiben);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const settings_style = {
        margin: 0,
        top: 'auto',
        left: 20,
        bottom: 20,
        position: 'fixed',
    };

    useEffect(() => {

        console.log("Token in home: " + token);
        console.log("ClientConfig in home: " + JSON.stringify(clientConfig));

        if (navigator.geolocation) {
            console.log("Starting geo location");
            setGeoLocationId(navigator.geolocation.watchPosition(
                checkPosition,
                (err) => {
                    console.log(err);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            ));
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
        
        console.log("Checking for default public");
        let default_public = localStorage.getItem('DEFAULT_PUBLIC');
        if (!default_public) {
            default_public = false;
            localStorage.setItem("DEFAULT_PUBLIC", default_public);
        }
        setPublic(default_public==="true");
        loadLastTargets();
        
        window.addEventListener("focus", onFocus);

        return () => {
            console.log("Cleaning up event handlers of home component")
            window.removeEventListener("focus", onFocus);
            console.log("GeoLocationId: " + geoLocationId);
            try{
                geoLocationId && window.geolocation.clearWatch(geoLocationId);
            } catch(e){
                console.log("Error cleaning up geo location: " + e.message);
            };
        };

    },[]);

    const onFocus = () => {
        console.log("Setting end time because of focus");
        setEndtime(dayjs());
    };

    const loadLastTargets = ()=>{
        console.log("Loading last targets");

        fetch("api/last_targets",{
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) { 
            response.json().then((result) => {
                console.log("Got the following last targets: " + JSON.stringify(result));
                if (result!==null && result.length>0) {
                    setScheibennummer(result);
                }
                
            });
        });
        
    };

    const getSendGeoLoation =  ()=> {
        let item = localStorage.getItem("SEND_LOCATION");
        if (item && item==='true') {
            return true;
        } else return false;
    }

    const downloadExport = () => {
        console.log("Starting export");
        fetch("api/export",{
            headers: {
                "token": token
                //"Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) { 
            response.blob().then((result) => {
                //console.log("Got the following result: " + result);
                var url = window.URL.createObjectURL(result);
                var a = document.createElement('a');
                a.href = url;
                a.download = "export.csv";
                document.body.appendChild(a);
                a.click();    
                a.remove();
            });
        });
    }

    const isAdmin = () => {
        //console.log("Checking if user is admin");
        let parsed_token = parseToken(token);
        let groups = parsed_token.groups;
        if (groups === undefined || groups.length === 0)
            return false;

        return groups.includes(clientConfig.admin_group);

    }


    const checkPosition = (position) =>{

        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        const variableName = "LAST_POSITIVE_GEO_HIT";

        console.log("latitude: " + latitude + ", longitude: " + longitude);

        let latitudeIn = latitude >= 50.093798 && latitude <= 50.095529;
        let longitudeIn = longitude >= 8.546623 && longitude <= 8.552981;
        
        console.log("latitude: " + latitudeIn);
        console.log("longitude: " + longitudeIn);

        console.log("Send geo location state: " + getSendGeoLoation());

        if(getSendGeoLoation()){
            console.log("SENDING POSITION");
            sendPosition(latitude, longitude);
        }
        
        if (latitudeIn && longitudeIn && localStorage.getItem("START_TIME")==null ){
            console.log("Checkign position is true");
            setShowPositionDialog(true);
            localStorage.setItem(variableName, new Date().getTime());
        } else {
            let currentEpoch = new Date().getTime();
            let lastHit = localStorage.getItem(variableName);
            if(lastHit && currentEpoch>Number(lastHit) + 5*60*1000){
                setShowPositionDialog(false);
                localStorage.removeItem(variableName);
            }
            
        }
    
    }

    const sendPosition = (latitude, longitude) => {

        let message = {latitude, longitude};
    
        fetch("api/geo",{
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(message)
        });
    }


    const handleChange = (event) => {
        let {
            target: { value },
        } = event;
        console.log(value);
        value = value.sort(function (a, b) { return a - b; });
        console.log("Nach sort: " + value);
        setScheibennummer(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const leadingZero = (input) => {
        return ('0'  + input).slice(-2)
    }

    const handleSendButton = (event) => {
        let e = false;

        // Validate start time
        if (starttime === null) {
            setStartTimeError("Startzeit erforderlich");
            e = true;
        } else if (starttime != null && startTimeError) {
            setStartTimeError(null);
        }

        // Validate scheiben
        if (scheibennummer.length === 0) {
            setScheibenError("Mindestens eine Scheibe auswählen");
            e = true
        } else if (scheibennummer.length > 0 && scheibenError) {
            setScheibenError(null);
        }

        if(e){
            return;
        }

        //Setting last public default value
        localStorage.setItem("DEFAULT_PUBLIC", isPublic);

        //Nachricht bauen

        let message = {
            "name": displayName,
            "datum": leadingZero(date.date()) +"." + leadingZero((date.month()+1)) +"." + date.year(),
            "start": leadingZero(starttime.hour()) + ":" + leadingZero(starttime.minute()),
            "ende": leadingZero(endtime.hour()) + ":" + leadingZero(endtime.minute()),
            "scheiben" : scheibennummer,
            "gaeste": guests,
            "weitere_mitglieder": additionalMembers,
            "oeffentlich": isPublic
        };

        fetch("api/entry",{
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(message)
        }).then(function (response) { 
            console.log(response); 
            setIsSaveSuccessfull(true);
            setScheibennummer([]);
            setGuests("");
            setAdditionalMembers("");
            setEndtime(dayjs());
            loadNumberOfPresent();
            localStorage.removeItem("START_TIME");
            setStarttime(null);
        });
        loadLastTargets();
        
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'rgba(250, 250, 250, 0.9)',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        backdropfilter: 'blur(10px)',
    };

    const positionStyle = {
        borderColor: 'grey.500',
        borderWidth: '1px',
        borderStyle: 'solid',
        paddingBottom: '15px',
        borderRadius: '10px',
        width: '100%',
        alignContent: 'space-between',
        backgroundColor: '#708090'
    };


    return (
        <Stack spacing={2}>

            {showPositionDialog && 
                <Grid container spacing={1} direction="row" alignItems="center" sx={{ ...positionStyle}}>
                    <Grid item xs={6} alignItems="center">
                        <Typography align='center'>
                            Bist Du auf dem Platz und möchtest Deine Anwesenheit teilen?
                        </Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="center" >
                        <Button variant="contained" 
                        onClick={() => {
                            let time = new Date();
                            pushStartTime(time);
                            setShowPositionDialog(false);
                        }}>Ja</Button>
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                        <Button variant="contained"
                          onClick={() => {
                            setShowPositionDialog(false);
                          }}>Nein</Button>
                    </Grid>
                </Grid>
            }

            <TextField
                disabled
                id="standard-password-input"
                label="Name"
                autoComplete="current-password"
                variant="standard"
                value={displayName}
                onChange={(event) => setDisplayName(event.target.value)}
            />
            <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                <DatePicker label="Schießdatum"
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    required
                />
                <TimePicker
                    label="Begin"
                    value={starttime}
                    onChange={(newValue) => setStarttime(newValue)}
                    slotProps={{
                        textField: {
                            error: !!startTimeError,
                            helperText: startTimeError,
                        },
                    }}
                />
                <TimePicker
                    label="Ende"
                    value={endtime}
                    onChange={(newValue) => setEndtime(newValue)}
                />
            </LocalizationProvider>

            <FormControl error={scheibenError!=null}>
                <InputLabel id="demo-simple-select-label">Scheiben</InputLabel>
                <Select
                    multiple
                    input={<OutlinedInput id="select-multiple-chip" label="Scheiben" />}
                    value={scheibennummer}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip 
                                    onDelete={()=> handleRemoveScheibennummer(value)}
                                    key={value}
                                    label={value} 
                                    deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                    }
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {scheiben.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
                {scheibenError && <FormHelperText>Mindestens eine Scheibe auswählen</FormHelperText>}
            </FormControl>
            <TextField
                label="Weitere Vereinsmitglieder"
                variant="standard"
                value={additionalMembers}
                onChange={(event) => setAdditionalMembers(event.target.value)}
            />
            <TextField
                label="Gäste"
                variant="standard"
                value={guests}
                onChange={(event) => setGuests(event.target.value)}
            />

            <FormControl
                orientation="horizontal"
                sx={{justifyContent: 'space-between' }}
                >
                <Box>
                    <FormControlLabel
                        control={
                            <Switch 
                                checked={isPublic}
                                onChange={(event) => {setPublic(event.target.checked)}}
                            />
                        }
                        label="Für alle Mitglieder sichtbar"
                    />
                    <IconButton aria-label="delete" color="primary">
                        <InfoIcon 
                          onClick={() => {
                            setOpenPublicInfo(true);
                          }}/>
                    </IconButton>
                </Box>
            </FormControl>

            <Button variant="contained"
                onClick={handleSendButton}>
                Eintrag senden
            </Button>
            <Typography align='center'>
                <Link to="history">Historie</Link>
            </Typography>
            {isAdmin() &&
                <Typography align='center'>
                    <Link onClick={downloadExport}>Export</Link>
                </Typography>
            }
            <Snackbar 
                open={isSaveSuccessful}
                onClose={() => setIsSaveSuccessfull(false)}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                 }}
                >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                Dein Eintrag wurde gespeichert!
                </Alert>
            </Snackbar>
            <Modal
                open={openPublicInfo}
                onClose={()=> {setOpenPublicInfo(false)}}
                sx={{ ...modalStyle, width: "75%", maxWidth: "300px"}}
            >
                <Box>
                    <h3>Für alle Mitglieder sichtbar</h3>
                    <p>
                        Einträge, die diese Option gesetzt haben, sind in der Liste der Historie für alle Vereinsmitglieder sichtbar.
                        <br/>
                        <br/>
                        Deine letzte Einstellung wird gespeichert und beim nächsten Eintrag automatisch gesetzt.
                    </p>
                    <Button variant="contained" onClick={()=> {setOpenPublicInfo(false);}}>Schließen</Button>
                </Box>
            </Modal>
{/*             <Fab style={settings_style}
                variant="extended"
                size="small"
                color="primary"
                onClick={handleMenuClick}
            >
                    
                <SettingsIcon />
            </Fab>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={resetLogin}>Reset Login</MenuItem>
                <MenuItem onClick={()=> {pushStartTime(new Date())}}>Anwesenheit teilen</MenuItem>
            </Menu> */}
        </Stack>


    );
};

export default Home;